@import url("https://fonts.googleapis.com/css2?family=Redressed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,700&family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap");
#header {
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: var(--background_secondary);
}
#header span {
  color: var(--text_third);
}

#logo-name {
  font-family: "Redressed", cursive;
  color: var(--text_secondary);
}
#title-quran {
  font-family: "Amiri", serif;
}
input,
textarea {
  width: 100%;
}
.card:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.advanced-card {
  height: 55vh;
  padding: 3rem 1rem;
  background: #fff;
  position: relative;
  display: flex;
  align-items: flex-end;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  transition: 0.3s ease-in-out;
}
.advanced-card:hover {
  transform: translateY(20px);
}
.advanced-card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(235, 169, 63, 0.5),
    rgba(235, 169, 63, 1)
  );
  z-index: 2;
  transition: var(--transition);
  opacity: 0;
}
.advanced-card:hover:before {
  opacity: 1;
}
.advanced-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.advanced-card .info {
  position: relative;
  z-index: 3;
  color: #fff;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.5s all;
}
.advanced-card:hover .info {
  opacity: 1;
  transform: translateY(0px);
}
.advanced-card .info p {
  font-size: 15px;
  margin-top: 8px;
  margin-bottom: 20px;
}

footer a {
  color: white;
  transition: var(--transition);
}
footer a:hover {
  color: var(--text_third);
}
