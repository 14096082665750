@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&family=Ms+Madi&display=swap");
:root {
  --brand_primary: #bf8931;
  --brand_secondary: #eba93f;
  --background: #d3d3d3;
  --background_secondary: #f8f8f8;
  --text_primary: #c38b30;
  --text_secondary: #bf8931;
  --text_third: #eba93f;
  --text_forth: #7f7f7f;
  --transition: 0.3s all;
}
/* Width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background-color: white;
  cursor: pointer;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--text_third);
  border-radius: 25px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--text_primary);
  cursor: pointer;
}

body {
  font-family: "Cairo", sans-serif;
}
a {
  text-decoration: none;
}
p {
  letter-spacing: 1px;
}
.title {
  color: var(--text_third);
}
.subtitle {
  color: var(--text_forth);
}

.btn {
  color: white;
  background: var(--text_third);
  transition: var(--transition);
  padding: 10px 20px;
  border-radius: 2px;
}
.btn:hover {
  color: white;
  margin-right: 10px;
  background: var(--text_primary);
}
